/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-modal::part(content) {
  min-width: 1100px;
  min-height: 600px;
  transition: 150ms;
}

ion-modal {
  --border-radius: 40px;
  color: black;
}

ion-content {
  --background: #f1f1f1;
  color: black;
}

ion-button {
  --border-radius: 5px;
}

ion-alert {
  --max-width: 500px !important;

  .alert-wrapper {
    border: 1px solid black;
    .alert-button-group {
      flex-direction: unset;
      flex-wrap: nowrap;
      justify-content: center;

      .alert-button {
        width: 160px;
        margin-left: 8px;
        margin-right: 8px;
        text-transform: none;
        text-align: center;
        background: var(--ion-color-primary);
        color: white;
        border-radius: 5px;
        font-weight: 500 !important;
      }

      .alert-button-role-cancel {
        background: none !important;
        color: var(--ion-color-primary);
        border: solid 1px var(--ion-color-primary);
      }

      .alert-button-inner {
        justify-content: center;
      }
    }
  }

  .button-primary {
    background: var(--ion-color-secondary) !important;
    color: white !important;
  }

  .button-secondary {
    background: var(--ion-color-secondary) !important;
    color: var(--ion-color-primary) !important;
  }

  .button-danger {
    background: var(--ion-color-danger) !important;
    color: white !important;
  }
}

ion-button::part(native) {
  border-radius: 50px !important;
  text-transform: none !important;
}

.button-primary::part(native) {
  background: var(--ion-color-primary) !important;
  color: white !important;
}

ion-searchbar {
  --box-shadow: none !important;
  .searchbar-input {
    border: 1px solid black !important;
    border-radius: 0px !important;
  }
}

ion-segment-button {
  margin-left: 5px;
  margin-right: 5px;
}

ion-segment-button::part(native) {
  color: black;
  border-radius: 5px;
  border: 2px solid #efefef;
  text-transform: none;
}

ion-segment-button.segment-button-checked::part(native) {
  background: var(--ion-color-primary) !important;
  color: white !important;
}

ion-segment-button::part(indicator) {
  display: none;
}

.mat-datepicker-popup {
  position: absolute;
  top: calc(50% - 216.5px) !important;
  left: calc(50% - 168px) !important;
}

.mat-calendar-body-selected {
  background-color: #004586;
}

doka-button {
  cursor: pointer;
}